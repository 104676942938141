<template>
    <div class="page">
        <el-backtop><i class="el-icon-caret-top"></i></el-backtop>
        <img src="../../assets/images/product/pingcyun.jpg" alt="" srcset="" class="imgs1">
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    created(){
    },
    methods: {
    }
    
}
</script>
<style lang="scss" scoped>
.page{
    background: #F8F9FA;
    position: relative;
    // display: inline-block;
    // width: 100%;
    .imgs1{
        // width: 1509px;
        display: flex;
        margin: auto;
        position: relative;
        z-index: 1;
    }
}
</style>